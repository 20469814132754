import { configureStore } from "@reduxjs/toolkit";
import ProductSlice from "./slice/Product-Slice";

import FavoriteSlice from "./slice/FvoriteCard-Slice";

export const store = configureStore({
    reducer: {
        products: ProductSlice,
        card: FavoriteSlice
    }
});