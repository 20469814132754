import React from 'react'

//style
import '../../style/App.css';
import '../../style/Gallary_Butcher.css';
import '../../style/media/Gallary_Butcher-media.css';

//images
import img1 from '../../images/butcher/Gallary/Gallary1.webp';
import img2 from '../../images/butcher/Gallary/Gallary2.webp';
import img3 from '../../images/butcher/Gallary/Gallary3.webp';
import img4 from '../../images/butcher/Gallary/Gallary4.webp';
import img5 from '../../images/butcher/Gallary/Gallary5.webp';
import img6 from '../../images/butcher/Gallary/Gallary6.webp';
import img7 from '../../images/butcher/Gallary/Gallary7.webp';
import img8 from '../../images/butcher/Gallary/Gallary8.webp';
import img9 from '../../images/butcher/Gallary/Gallary9.webp';

const Gallary_Butcher = () => {
  return (
    <>
      <div className="gallary_butcher">
        <div className="container">
          <h1>
            Gallary
          </h1>

          <div className="contr row">
            <div className="colc col-lg-4 col-sm-12 mx-auto">
              <div className="upo">
                <a href={img1}
                  data-lightbox="gallery"
                  data-title={img1}>       
                
                  <img src={img1} loading='lazy' alt={img1} />
                </a>
              </div>

              <div className="dwm">
                <a href={img2}
                  data-lightbox="gallery"
                  data-title={img2}>       
                
                  <img src={img2} loading='lazy' alt={img2} />
                </a>

                <a href={img3}
                  data-lightbox="gallery"
                  data-title={img3}>       
                
                  <img src={img3} loading='lazy' alt={img3} />
                </a>
              </div>
            </div>

            <div className="colc col-lg-4 col-sm-12 mx-auto">
              <div className="upo">
                <a href={img6}
                  data-lightbox="gallery"
                  data-title={img6}>       
                
                  <img src={img6} loading='lazy' alt={img6} />
                </a>              
              </div>

              <div className="dwm">
                <a href={img4}
                    data-lightbox="gallery"
                    data-title={img4}>       
                  
                    <img src={img4} loading='lazy' alt={img4} />
                </a>

                <a href={img5}
                  data-lightbox="gallery"
                  data-title={img5}>       
                
                  <img src={img5} loading='lazy' alt={img5} />
                </a>                
              </div>
            </div>

            <div className="colc col-lg-4 col-sm-12 mx-auto">
              <div className="upo">
                <a href={img7}
                    data-lightbox="gallery"
                    data-title={img7}>       
                  
                  <img src={img7} loading='lazy' alt={img7} />
                </a>
              </div>

              <div className="dwm">
                <a href={img8}
                    data-lightbox="gallery"
                    data-title={img8}>       
                  
                    <img src={img8} loading='lazy' alt={img8} />
                </a>

                <a href={img9}
                  data-lightbox="gallery"
                  data-title={img9}>       
                
                  <img src={img9} loading='lazy' alt={img9} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Gallary_Butcher
