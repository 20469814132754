import React from "react";
import { Link } from "react-router-dom";

//style
import "../../style/App.css";
import "../../style/Category_Butcher.css";
import "../../style/media/Category-media.css";

//images
import dish from "../../images/butcher/food-icon/capricorn.svg";
import cow   from "../../images/butcher/food-icon/cow-silhouette-svgrepo-com.svg";
import brain from "../../images/butcher/food-icon/brain-illustration-1-svgrepo-com.svg";
import dish4 from "../../images/butcher/food-icon/food-meat-chicken-svgrepo-com.svg";
import dish5 from "../../images/butcher/food-icon/liver-svgrepo-com.svg";
import dish6 from "../../images/butcher/food-icon/meat-cut-svgrepo-com.svg";
import dish7 from "../../images/butcher/food-icon/meat-on-the-bone-1-svgrepo-com.svg";
import dish8 from "../../images/butcher/food-icon/sheep-looking-right-svgrepo-com.svg";
import burger from "../../images/butcher/food-icon/burger-svgrepo-com.svg";
import sausage from "../../images/butcher/food-icon/sausage-on-fork-svgrepo-com.svg";

const productsBU = [
  {
    name: "كندوز",
    names: "صغير ممتاز",
    image: dish6,
    url: "/menu1",
  },
  {
    name: "كندوز",
    names: " بالعظم",
    image: dish7,
    url: "/menu2",
  },
  {
    name: "فيتامينات ",
    names: "اللحوم",
    image: cow,
    url: "/menu3",
  },
  {
    name: "فواكه",
    names: "اللحوم",
    image: brain,
    url: "/menu4",
  },
  {
    name: "اصناف",
    names: "بالحجز",
    image: dish5,
    url: "/menu5",
  },
  {
    name: "ضاني",
    names: "ممتاز",
    image: dish8,
    url: "/menu6",
  },
  {
    name: "ماعز",
    image: dish,
    url: "/menu7",
  },
  {
    name: "مصنعات",
    names: "",
    image: sausage,
    url: "/menu8",
  },
  {
    name: "الطيور",
    image: dish4,
    url: "/menu9",
  },
  {
    name: "متبلات ",
    names: "حمدي شلبي",
    image: burger,
    url: "/menu10",
  },
];

const CategoryButcherCercile = () => {
  return (
    <div className="container">
      <div className="dwn">
        <div className="row">
          {productsBU.map((product, index) => (
            <Link
              to={product.url}
              className="bocx col-lg-3 col-sm-12 mx-auto"
              key={index}
            >
              <img
                src={product.image || dish}
                loading="lazy"
                alt={product.name}
              />

              <h2>
                {product.name}
                <span>{product.names}</span>
              </h2>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategoryButcherCercile;
