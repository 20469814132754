import React from "react";

import NavBarButcher from "../../component/NavbarButcher";
import SpecialItemButcher from "../Pages/SpecialItemButcher";
import CategoryButcherCercile from "../Pages/CategoryButcherCercile";
import ItemCategorey from "../Pages/ItemCategorey";
import FooterButcher from "../../component/FooterButcher";
import InsertMenu1 from "./menu/InsertMenu1";

const ButcherMenuPage = () => {
  return (
    <>
      {/* <SpecialItemButcher /> */}
      <CategoryButcherCercile />
      <ItemCategorey />
    </>
  );
};

export default ButcherMenuPage;
