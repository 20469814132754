import { useDispatch, useSelector } from "react-redux";
import { GetOffer } from "../../redux/slice/Product-Slice";
import React, { useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

//style
import "../../style/App.css";
import "../../style/CategoryButcher.css";
import "../../style/media/CategoryButcher-media.css";

//images

const UrlApi = "https://menu.hamdyshalaby.com/";
const CategoryButcher = () => {
  const product = useSelector((state) => state.products);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetOffer());
    window.scrollTo(0, 0);
  }, [dispatch]);

  return (
    <>
      <div className="offerr">
        <div className="container">
          <h1>عروضنا</h1>

          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              320: {
                slidesPerView: 1.5,
                spaceBetween: 10,
              },
              425: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              640: {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              1024: {
                slidesPerView: 3.5,
                spaceBetween: 30,
              },
            }}
            className="row boxrt-cont"
          >
            {product.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="boxrt col-lg-4">
                  <img src={`${UrlApi}${item.image}`} alt="" />

                  <h1 className="title">{item.name}</h1>

                  <div className="price">
                    <p className="text-white">
                      {item.coupon_price != null ? (
                        <>
                          <span>سعر المنتج : </span>
                          <s>{Math.round(item.price)} ج</s>
                          <span> {Math.round(item.final_price)} ج</span>
                        </>
                      ) : (
                        <p>سعر المنتج : {Math.round(item.price)} ج</p>
                      )}
                    </p>

                    <p className="discount">
                      {Math.round(parseFloat(item.coupon_price))}%
                    </p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default CategoryButcher;
