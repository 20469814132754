import CategoryButcherCercile from './CategoryButcherCercile'
import CategoryButcher from './CategoryButcher'
import ItemCategorey from './ItemCategorey'

export default function OffersPage() {
    return (
        <div>
            <div className='mt-5'>
                <ItemCategorey />
            </div>
            <CategoryButcherCercile />
            <CategoryButcher />
        </div>
    )
}
