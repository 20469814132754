import React from "react";

//style
import "../style/App.css";
import "../style/NavBar_Butcher.css";
import "../style/media/NavBar_Butcher-media.css";

//images
import brand from "../images/butcher/LogoButcher.webp";

//fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

const links = [
  { name: "الرئيسية", url: "/" },
  { name: "قائمة الطعام", url: "menubutcher" },
  { name: "عروضنا", url: "offer" },
  { name: " تواصل معنا", url: "https://wa.me/201060909066" },
];

const NavBar = () => {
  return (
    <>
      <nav class="navBar">
        <div clss="contain ">
          <div className="navbar-uppe ">
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              <span class="navbar-toggler-icon">
                <i className="Icon-nav-Custom">
                  <FontAwesomeIcon icon={faBars} />
                </i>
              </span>
            </button>

            <div
              class="offcanvas offcanvas-end bg-dark "
              tabindex="-1"
              id="offcanvasNavbar"
              aria-labelledby="offcanvasNavbarLabel"
            >
              <div class="offcanvas-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </div>

              <ul className="navbar-nave-soc">
                <li>
                  <a href="tel:17069">
                    <button className="delive">HOTLINE</button>
                  </a>
                </li>

                <li>
                  <a href="https://wa.me/201060909066">
                    <button className="delive">اتصل بنا</button>
                  </a>
                </li>
              </ul>

              <ul className="navBar-nave">
                {links.map((item, index) => (
                  <li className="nav-item" key={index}>
                    <Link to={item.url} className="nav-link">
                      {item.name}
                    </Link>
                  </li>
                ))}

                <li className="nav-item">
                  <div to="/#contact" className="nav-link">
                    <div className="cotu">
                      <a
                        href="https://www.facebook.com/hamdy.shalaby.butcher/"
                        target="blank"
                      >
                        <FontAwesomeIcon icon={faFacebook} />
                      </a>

                      <a
                        href="https://www.facebook.com/hamdy.shalaby.butcher/"
                        target="blank"
                      >
                        <FontAwesomeIcon icon={faInstagram} />
                      </a>

                      <a href="https://wa.me/201060909066" target="blank">
                        <FontAwesomeIcon icon={faWhatsapp} />
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>

            <div className="brande">
              <img src={brand} alt="Brand Logo" loading="lazy" />
            </div>
          </div>

          {/* big site */}
          <div className="navbar-nav-con">
            <div className="brand">
              <img src={brand} alt="Brand Logo" loading="lazy" />
            </div>

            <ul className="navBar-nav">
              {links.map((item, index) => (
                <li className="nav-item" key={index}>
                  <Link to={item.url} className="nav-link">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>

            <ul className="navbar-nave-soce">
              <li>
                <a href="tel:17069">
                  <button className="delive">HOTLINE</button>
                </a>
              </li>

              <li>
                <a href="https://wa.me/201060909066">
                  <button className="delive">اتصل بنا</button>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBar;
