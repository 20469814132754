import { Outlet } from "react-router-dom";
import NavBar from "../component/NavbarButcher";
import Footer from "../component/FooterButcher";
import FooterButcher from "../component/FooterButcher";


export default function Root() {
    return (
        <>

            <NavBar />
            <Outlet />
            <FooterButcher />
            
        </>
    )
}
