import React from 'react'

//pages

import NavBarButcher from '../../component/NavbarButcher';
import HeaderButcher from '../Pages/HeaderButcher';
import CategoryButcher from '../Pages/CategoryButcher';
import GallaryButcher from '../Pages/GallaryButcher';
import FooterButcher from '../../component/FooterButcher';

const ButcherPage = () => {
  return (
    <>


      <HeaderButcher />
      <CategoryButcher />
      <GallaryButcher />

    </>
  )
}

export default ButcherPage
