import { createBrowserRouter, RouterProvider } from "react-router-dom";

//component
import Root from "./router/Root";



//style
import "./style/App.css";
// import InsertMenu1 from "./router/Pages/resturantMenu/InsertMenu1";
// import InsertMenu2 from "./router/Pages/resturantMenu/InsertMenu2";
// import InsertMenu3 from "./router/Pages/resturantMenu/InsertMenu3";
// import InsertMenu4 from "./router/Pages/resturantMenu/InsertMenu4";
// import InsertMenu5 from "./router/Pages/resturantMenu/InsertMenu5";
// import Favoritepage from "./component/Favoritepage";
import ButcherPage from "./router/Pages/ButcherPage";
import ButcherMenuPage from "./router/Pages/ButcherMenuPage";
import InsertMenu1 from "./router/Pages/menu/InsertMenu1";
import InsertMenu2 from "./router/Pages/menu/InsertMenu2";
import InsertMenu3 from "./router/Pages/menu/InsertMenu3";
import InsertMenu4 from "./router/Pages/menu/InsertMenu4";
import InsertMenu5 from "./router/Pages/menu/InsertMenu5";
import InsertMenu6 from "./router/Pages/menu/InsertMenu6";
import InsertMen7 from "./router/Pages/menu/InsertMenu7";
import InsertMenu8 from "./router/Pages/menu/InsertMenu8";
import InsertMenu9 from "./router/Pages/menu/InsertMenu9";
import InsertMenu10 from "./router/Pages/menu/InsertMenu10";
import OffersPage from "./router/Pages/OffersPage";

const routers = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        index: true, element: <ButcherPage />
      },
      {
        path: "menubutcher",
        element: <ButcherMenuPage />
      },
      {
        path: "offer",
        element: <OffersPage />
      },
      {
        path: "menu1",
        element: <InsertMenu1 />
      },
      {
        path: "menu2",
        element: <InsertMenu2 />
      },
      {
        path: "menu3",
        element: <InsertMenu3 />
      },
      {
        path: "menu4",
        element: <InsertMenu4 />
      },
      {
        path: "menu5",
        element: <InsertMenu5 />
      },
      {
        path: "menu6",
        element: <InsertMenu6 />
      },
      {
        path: "menu7",
        element: <InsertMen7 />
      },
      {
        path: "menu8",
        element: <InsertMenu8 />
      },
      {
        path: "menu9",
        element: <InsertMenu9 />
      },
      {
        path: "menu10",
        element: <InsertMenu10 />
      }
    ]
  },

]);

function App() {
  return <RouterProvider router={routers} />;
}

export default App;
