import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";



const UrlApi = "https://menu.hamdyshalaby.com/api"

export const GetMenu1 = createAsyncThunk("productsSlice/GetMenu1", async () => {
  const res = await fetch(`${UrlApi}/menus/18`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu2 = createAsyncThunk("productsSlice/GetMenu2", async () => {
  const res = await fetch(`${UrlApi}/menus/19`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu3 = createAsyncThunk("productsSlice/GetMenu3", async () => {
  const res = await fetch(`${UrlApi}/menus/20`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu4 = createAsyncThunk("productsSlice/GetMenu4", async () => {
  const res = await fetch(`${UrlApi}/menus/21`);
  const data = await res.json();
  return data.data.menus;

})
export const GetMenu5 = createAsyncThunk("productsSlice/GetMenu5", async () => {
  const res = await fetch(`${UrlApi}/menus/22`);
  const data = await res.json();
  return data.data.menus;

})

export const GetMenu6 = createAsyncThunk("productsSlice/GetMenu6", async () => {
  const res = await fetch(`${UrlApi}/menus/23`);
  const data = await res.json();
  return data.data.menus;

})
export const GetMenu7 = createAsyncThunk("productsSlice/GetMenu7", async () => {
  const res = await fetch(`${UrlApi}/menus/24`);
  const data = await res.json();
  return data.data.menus;

})
export const GetMenu8 = createAsyncThunk("productsSlice/GetMenu8", async () => {
  const res = await fetch(`${UrlApi}/menus/25`);
  const data = await res.json();
  return data.data.menus;

})
export const GetMenu9 = createAsyncThunk("productsSlice/GetMenu9", async () => {
  const res = await fetch(`${UrlApi}/menus/26`);
  const data = await res.json();
  return data.data.menus;

})
export const GetMenu10 = createAsyncThunk("productsSlice/GetMenu10", async () => {
  const res = await fetch(`${UrlApi}/menus/27`);
  const data = await res.json();
  return data.data.menus;

})

export const GetOffer = createAsyncThunk("productsSlice/GetOffer", async () => {
  const res = await fetch(`${UrlApi}/menus/spcail/28`);
  const data = await res.json();
  return data.data.menuSpecials;
})


const productsSlice = createSlice({
  initialState: [],
  name: "productsSlice",
  extraReducers: (builder) => {
    builder
      .addCase(GetMenu1.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu2.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu3.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu4.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu5.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu6.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu7.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu8.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu9.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetMenu10.fulfilled, (state, action) => {
        return action.payload
      })
      .addCase(GetOffer.fulfilled, (state, action) => {
        return action.payload
      })
  }
})


export default productsSlice.reducer;