import React from 'react'

//style
import '../style/App.css';
import '../style/Footer-Butcher.css';
import '../style/media/Footer-Butcher-media.css';

// Import the FontAwesomeIcon component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Import specific icons from the free-solid-svg-icons package
import { faFacebook, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import logo from '../images/butcher/LogoButcher.webp';

const FooterButcher = () => {
  return (
    <>
      <section className='footerbu'>
        <div className="container">
          <div className="row web">
            <div className="re col-lg-3 col-sm-6 mx-auto">
              <img src={logo} alt="" />

              <p>
                Lorem ipsum dolor sit amet consectetur 
                adipisicing elit. Excepturi obcaecati 
                nihil cum nesciunt consequatur consequuntur 
              </p>
            </div>

            <div className="ro col-lg-3 col-sm-6 mx-auto">
              <h2>
                القوائم 
              </h2>

              <ul>
                <li>
                  <a href="">
                    الرئيسية
                  </a>
                </li>


                <li>
                  <a href="">
                    قائمة الطعام    
                  </a>
                </li>

                <li>
                  <a href="">
                    عروضنا 
                  </a>
                </li>

                <li>
                  <a href="">
                    تواصل معنا  
                  </a>
                </li>
              </ul>
            </div>

            <div className="ru col-lg-3 col-sm-6 mx-auto pt-4">
              <div className="listitem">
                <div className="cotu">
                  <a href="https://www.facebook.com/hamdy.shalaby.butcher/" target='blank'>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
      
                  <a href="https://www.facebook.com/hamdy.shalaby.butcher/" target='blank'>
                    <FontAwesomeIcon icon={faInstagram} />                
                  </a>
      
                  <a href="https://wa.me/201060909066" target='blank'>
                    <FontAwesomeIcon icon={faWhatsapp} />                
                  </a>
                </div>


                <ul className='listul'>
                  <li className='listli'>
                    <a href="">
                      معرض الصور 
                    </a>
                  </li>

                  <li className='listli'>
                    <a href="">
                      معرض الصور 
                    </a>
                  </li>

                  <li className='listli'>
                    <a href="">
                      معرض الصور 
                    </a>
                  </li>

                  <li className='listli'>
                    <a href="">
                      معرض الصور 
                    </a>
                  </li>
                </ul>
              </div> 
            </div>
          </div>

          <div className="row mob">
            <div className="re col-lg-3 col-sm-6 mx-auto">
              <img src={logo} alt="" />

              <p>
                Lorem ipsum dolor sit amet consectetur 
                adipisicing elit. Excepturi obcaecati 
                nihil cum nesciunt consequatur consequuntur 
              </p>
            </div>

            <div className="conty re col-lg-3 col-sm-6 mx-auto">
            <div className="ro mx-auto">
              <h2>
                القوائم 
              </h2>

              <ul>
                <li>
                  <a href="">
                    الرئيسية
                  </a>
                </li>


                <li>
                  <a href="">
                    قائمة الطعام    
                  </a>
                </li>

                <li>
                  <a href="">
                    عروضنا 
                  </a>
                </li>

                <li>
                  <a href="">
                    تواصل معنا  
                  </a>
                </li>
              </ul>
            </div>

            <div className="ru mx-auto">
              <div className="listitem">
                <div className="cotu">
                  <a href="https://www.facebook.com/hamdy.shalaby.butcher/" target='blank'>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
      
                  <a href="https://www.facebook.com/hamdy.shalaby.butcher/" target='blank'>
                    <FontAwesomeIcon icon={faInstagram} />                
                  </a>
      
                  <a href="https://wa.me/201060909066" target='blank'>
                    <FontAwesomeIcon icon={faWhatsapp} />                
                  </a>
                </div>


                <ul className='listul'>
                  <li className='listli'>
                    <a href="">
                      معرض الصور 
                    </a>
                  </li>

                  <li className='listli'>
                    <a href="">
                      معرض الصور 
                    </a>
                  </li>

                  <li className='listli'>
                    <a href="">
                      معرض الصور 
                    </a>
                  </li>

                  <li className='listli'>
                    <a href="">
                      معرض الصور 
                    </a>
                  </li>
                </ul>
              </div> 
            </div>
            </div>
          </div>
        </div>

        <footer>
          <div class="container">
            <div class="rhe">
              <p>
                copyright 2024 @ ALL right reserved design by 
                  
                <a href="https://mrtechv2.netlify.app/" target='blank'>
                  MR Tech 
                </a>
              </p>
            </div>
          </div>
        </footer>
      </section>
    </>
  )
}

export default FooterButcher


{/* <div className="timing">
                <div className="cotu">
                  <a href="https://www.facebook.com/hamdy.shalaby.butcher/" target='blank'>
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
      
                  <a href="https://www.facebook.com/hamdy.shalaby.butcher/" target='blank'>
                    <FontAwesomeIcon icon={faInstagram} />                
                  </a>
      
                  <a href="wa/.." target='blank'>
                    <FontAwesomeIcon icon={faWhatsapp} />                
                  </a>
                </div>
              </div> */}