import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import {
  Autoplay,
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
} from "swiper/modules";
//style
import "../../style/App.css";
import "../../style/ItemCatgory.css";
import "../../style/media/ItemCatgoryButcher-media.css";

import hedimg from "../../images/butcher/Header_Img.webp";
import { Link } from "react-router-dom";

const ItemCategorey = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);

  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty("--progress", 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return (
    <>
      <div className="itemcaty">
        <div className="container">
          <Swiper
            cssMode={true}
            navigation={true}
            mousewheel={true}
            keyboard={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              onAutoplayTimeLeft,
            }}
            modules={[Autoplay, Navigation, Pagination, Mousewheel, Keyboard]}
            className="item"
          >
            <SwiperSlide className="offer-back">
              <div className="container">
                <div className="contain">
                  <div className="overla"></div>

                  <div className="row">
                    <div className="up">
                      <div className="sidlt col-lg-6 col-sm-6 mx-auto">
                        <h1>عروض خاصة</h1>

                        <p>
                          <span className="line"></span>
                          عرض اليوم الواحد
                          <span className="line"></span>
                        </p>
                        <Link to={"/offer"}>
                          <button className="btnoff">عرض المزيد</button>
                        </Link>
                      </div>

                      <div className="sidri col-lg-6 col-sm-6 mx-auto">
                        <img src={hedimg} loading="lazy" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide className="offer-back2">
              <div className="container">
                <div className="contain">
                  <div className="overla"></div>

                  <div className="row">
                    <div className="up">
                      <div className="sidlt col-lg-6 col-sm-6 mx-auto">
                        <h1>عروض خاصة</h1>

                        <p>
                          <span className="line"></span>
                          عرض اليوم الواحد
                          <span className="line"></span>
                        </p>

                        <Link to={"/offer"}>
                          <button className="btnoff">عرض المزيد</button>
                        </Link>
                      </div>

                      <div className="sidri col-lg-6 col-sm-6 mx-auto">
                        <img src={hedimg} loading="lazy" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

export default ItemCategorey;
