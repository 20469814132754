import React, { useRef } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

//style
import '../../style/App.css';
import '../../style/Header_Butcher.css';
import '../../style/media/Header_Butcher-media.css';

//images
import hedimg from '../../images/butcher/Header_Img.webp';
import CategoryButcherCercile from './CategoryButcherCercile';

const Header_Butcher = () => {
  const progressCircle = useRef(null);
  const progressContent = useRef(null);
  
  const onAutoplayTimeLeft = (s, time, progress) => {
    progressCircle.current.style.setProperty('--progress', 1 - progress);
    progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
  };

  return (
    <header className='hedbut'>
      <Swiper
        spaceBetween={10}
        centeredSlides={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        navigation={true}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          425: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="hedbut"
      >
        <SwiperSlide className='containew'>
          <div className="overla"></div>
          
          <div className="container">
            <div className="contain">
              <div className="row">
                <div className="up">
                  <div className="col-lg-6 mx-auto hedt">
                    <p id='upp'> 
                      اهلا بكم في  
                    </p>
                    
                    <h1>
                      جزارة
                    </h1>
                    
                    <p id='signe'>
                      <span className='line'></span>
                      حمدي شلبي
                      <span className='line'></span>
                    </p>
                  </div>

                  <div className="col-lg-6 col-sm-12 mx-auto hedi">
                    <img src={hedimg} alt="greeld" loading='lazy' />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className='offer-back'>
          <div className="container">
              <div className="contain">
              
              <div className="overla"></div>

              <div className="row">
                  <div className="up">
                    <div className="sidlt col-lg-6 col-sm-6 mx-auto">
                      <h1>
                        عروض خاصة 
                      </h1>
                      
                      <p>
                        <span className='line'></span>
                          عرض اليوم الواحد 
                        <span className='line'></span>
                      </p>

                      <button className='btnoff'>
                        عرض المزيد 
                      </button>
                    </div>

                    <div className="sidri col-lg-6 col-sm-6 mx-auto">
                      <img src={hedimg} loading='lazy' alt="" />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide className='offer-back2'>
          <div className="container">
              <div className="contain">
              
              <div className="overla"></div>

              <div className="row">
                  <div className="up">
                    <div className="sidlt col-lg-6 col-sm-6 mx-auto">
                      <h1>
                        عروض خاصة 
                      </h1>
                      
                      <p>
                        <span className='line'></span>
                          عرض اليوم الواحد 
                        <span className='line'></span>
                      </p>

                      <button className='btnoff'>
                        عرض المزيد 
                      </button>
                    </div>

                    <div className="sidri col-lg-6 col-sm-6 mx-auto">
                      <img src={hedimg} loading='lazy' alt="" />
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      
      <CategoryButcherCercile/>
    </header>
  );
};

export default Header_Butcher;
