import ButcherMenuPage from "../ButcherMenuPage";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetMenu9 } from "../../../redux/slice/Product-Slice";

//style
import "../../../style/App.css";
import "../../../style/ItemCatgory.css";
import "../../../style/media/ItemCatgoryButcher-media.css";

const UrlApi = "https://menu.hamdyshalaby.com/"

export default function InsertMenu5() {

    const product = useSelector((state) => state.products)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetMenu9());
        window.scrollTo(0, 0);
    }, [dispatch])

    return (
        <>
            <ButcherMenuPage />
            <div className="text-center my-3">
                <h1>
                    الطيور
                </h1>
            </div>
            <div className="itemcaty">
                <div className="container">
                    <div className="boxbuy-cont row">
                        {
                            product.map((item, index) => (
                                <div className=" boxbuy col-lg-3 col-sm-5 mx-auto" key={index}>
                                    <img src={`${UrlApi}${item.image}`} alt="" />


                                    <h2 className="mt-2">
                                        {item.name}
                                    </h2>

                                    <h5 className='descrep'>
                                        {item.desc}
                                    </h5>

                                    <p className="text-white">
                                        {item.coupon_price !== null ? (
                                            <>
                                                <s>{Math.round(item.price)} ج</s> {Math.round(item.final_price)} ج
                                            </>
                                        ) : (
                                            `${Math.round(item.price)} ج`
                                        )}
                                    </p>

                                    {/* <button className='btnBy'>
                                        اضافة للمفضله
                                    </button> */}
                                </div>
                            ))
                        }




                    </div>
                </div>
            </div>
        </>
    )
}
